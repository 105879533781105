import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import React, { useReducer, useState } from "react";
import { PairType, StockQuote } from "../types";

function initializeOpenStates(pairTable: Record<number, PairType>) {
  const initialState = new Map(
    Object.keys(pairTable).map((usdSymbolId) => [parseInt(usdSymbolId), true])
  );
  return initialState;
}

const reducer = (state: Map<number, boolean>, toToggle: number) => {
  const newValue = !(state.get(toToggle) ?? true);
  console.log("current state", state);
  const newMap = new Map(state);
  newMap.set(toToggle, newValue);
  return newMap;
};

export default function RateTable({
  pairTable,
  disabled,
  quotes,
}: {
  pairTable: Record<number, PairType>;
  disabled: boolean;
  quotes: Record<number, StockQuote | null>;
}) {
  const [open, toggleRow] = useReducer(
    reducer,
    pairTable,
    initializeOpenStates
  );

  const [lowestAsk, setLowestAsk] = useState(Infinity);
  const [highestBid, setHighestBid] = useState(-Infinity);

  const rows = Object.values(pairTable).map((pair) => {
    const { cadSymbolId, usdSymbolId } = pair;
    const cadQuote = quotes[cadSymbolId];
    const usdQuote = quotes[usdSymbolId];
    let xCadBid = 0,
      xCadAsk = 0;
    if (cadQuote && usdQuote) {
      xCadBid = cadQuote.bidPrice / usdQuote.askPrice;
      xCadAsk = cadQuote.askPrice / usdQuote.bidPrice;

      if (xCadAsk < lowestAsk) {
        console.log("Setting lowest ask", xCadAsk);
        setLowestAsk(xCadAsk);
      }
      if (xCadBid > highestBid) {
        console.log("Setting highest bid", xCadBid);
        setHighestBid(xCadBid);
      }
    }

    const newPair: PairType & Record<string, any> = { ...pair };

    newPair.cadQuote = cadQuote;
    newPair.usdQuote = usdQuote;
    newPair.xCadAsk = xCadAsk;
    newPair.xCadBid = xCadBid;
    return newPair;
  });

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Description</TableCell>
            <TableCell align="right">Average Volume (3 months)</TableCell>
            <TableCell align="right">CAD VWAP/USD VWAP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((pair, i) => (
            <React.Fragment key={i}>
              <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => toggleRow(pair.usdSymbolId)}
                  >
                    {open.get(pair.usdSymbolId) ?? true ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {pair.description}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse
                    in={open.get(pair.usdSymbolId) ?? true}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Currency</TableCell>
                            <TableCell>Ticker</TableCell>
                            <TableCell align="right">Bid</TableCell>
                            <TableCell align="right">Ask</TableCell>
                            <TableCell align="right">Crossed Bid</TableCell>
                            <TableCell align="right">Crossed Ask</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              CAD
                            </TableCell>
                            <TableCell>{pair.cadTicker}</TableCell>
                            {pair.cadQuote && pair.usdQuote && (
                              <>
                                <TableCell align="right">
                                  {pair.cadQuote.bidPrice.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  {pair.cadQuote.askPrice.toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    backgroundColor:
                                      pair.xCadBid === highestBid
                                        ? "lightsalmon"
                                        : undefined,
                                  }}
                                >
                                  {pair.xCadBid.toFixed(4)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    backgroundColor:
                                      pair.xCadAsk === lowestAsk
                                        ? "lightgreen"
                                        : undefined,
                                  }}
                                >
                                  {pair.xCadAsk.toFixed(4)}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              USD
                            </TableCell>
                            <TableCell>{pair.usdTicker}</TableCell>

                            {pair.usdQuote && pair.cadQuote && (
                              <>
                                <TableCell align="right">
                                  {pair.usdQuote.bidPrice}
                                </TableCell>
                                <TableCell align="right">
                                  {pair.usdQuote.askPrice}
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    pair.usdQuote.bidPrice /
                                    pair.cadQuote.askPrice
                                  ).toFixed(4)}
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    pair.usdQuote.askPrice /
                                    pair.cadQuote.bidPrice
                                  ).toFixed(4)}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
