import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import axiosApi from "axios";
import { MAX_BACKEND_API_CALL_COUNT, ONE_HOUR, ONE_MINUTE } from "./constants";
import {
  MarginType,
  OptionQuotes,
  QuestradeCredentials,
  QuestradeSymbol,
  StockQuote,
  UserType,
} from "./types";
import { store } from "./store";
import { userActions } from "./reducers/userReducer";
import {
  getOptionStrikeFromSymbol,
  getOptionTypeFromSymbol,
  sleepUntil,
  transcribeRawCreds,
} from "./helpers";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://xactmargin-staging.herokuapp.com"
    : "http://localhost:3000";

const axios = axiosApi.create({
  baseURL,
  withCredentials: true,
});
const GRAPHQL_API = `${baseURL}/api/graphql`;

export function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: new HttpLink({
      uri: GRAPHQL_API,
      credentials: "include",
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
}

const apollo = createApolloClient();

const userInfoFragment = gql`
  fragment userInfo on User {
    id
    name
    email
    qtCred {
      accessToken
      refreshToken
      expiresAt
      apiServer
    }
    watchlist {
      symbolId
      symbol
    }
  }
`;

export async function getCurrentUser() {
  const res = await apollo.query({
    query: gql`
      query {
        authenticatedItem {
          ... on User {
            ...userInfo
          }
        }
      }
      ${userInfoFragment}
    `,
    fetchPolicy: "no-cache",
  });
  return res.data;
}

export async function userSignOut() {
  const res = await apollo.mutate({
    mutation: gql`
      mutation {
        endSession
      }
    `,
  });
  return res.data;
}

export async function userSignIn({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const res = await apollo.mutate({
    mutation: gql`
      mutation ($email: String!, $password: String!) {
        authenticateUserWithPassword(email: $email, password: $password) {
          __typename
          ... on UserAuthenticationWithPasswordSuccess {
            item {
              ...userInfo
            }
          }
        }
      }
      ${userInfoFragment}
    `,
    variables: {
      email,
      password,
    },
  });
  return res?.data;
}

export async function updateUser({
  id,
  newValues,
}: {
  id: string;
  newValues: Record<string, any>;
}) {
  const res = await apollo.mutate({
    mutation: gql`
      mutation ($id: ID!, $newValues: UserUpdateInput!) {
        updateUser(where: { id: $id }, data: $newValues) {
          ...userInfo
        }
      }
      ${userInfoFragment}
    `,
    variables: { id, newValues },
  });
  return res.data;
}

export async function questradeAuthAndUpdateUser({
  id,
  credentials,
}: {
  id: string;
  credentials: QuestradeCredentials;
}) {
  const res = await apollo.mutate({
    mutation: gql`
      mutation (
        $id: ID!
        $accessToken: String!
        $apiServer: String!
        $refreshToken: String!
        $tokenType: String!
        $expiresAt: DateTime!
      ) {
        updateUser(
          where: { id: $id }
          data: {
            qtCred: {
              create: {
                accessToken: $accessToken
                refreshToken: $refreshToken
                tokenType: $tokenType
                apiServer: $apiServer
                expiresAt: $expiresAt
              }
            }
          }
        ) {
          ...userInfo
        }
      }
      ${userInfoFragment}
    `,
    variables: { id, ...credentials },
  });
  return res.data;
}

export async function addSymbolsToWatchlist({
  userId,
  symbols,
}: {
  userId: string;
  symbols: Array<QuestradeSymbol>;
}) {
  const symbolIds = symbols.map((symbol) => symbol.symbolId);

  const queryRes = await apollo.query({
    query: gql`
      query ($symbolIds: [Int!]) {
        securities(where: { symbolId: { in: $symbolIds } }) {
          symbolId
        }
      }
    `,
    variables: { symbolIds },
    fetchPolicy: "network-only",
  });

  const foundSymbols: Array<QuestradeSymbol> = queryRes.data.securities;
  const foundSymbolIds = new Set(foundSymbols.map((symbol) => symbol.symbolId));
  const symbolsNotFound: Array<QuestradeSymbol> = [];
  symbols.forEach((symbol) => {
    const { symbolId } = symbol;
    if (!foundSymbolIds.has(symbolId)) {
      symbolsNotFound.push(symbol);
    }
  });

  const createSymbolsRes = await apollo.mutate({
    mutation: gql`
      mutation ($symbols: [SecurityCreateInput!]!) {
        createSecurities(data: $symbols) {
          symbolId
        }
      }
    `,
    variables: { symbols: symbolsNotFound },
  });

  const createdSymbols: Array<QuestradeSymbol> =
    createSymbolsRes.data.createSecurities;

  const symbolsInDatabase = [...createdSymbols, ...foundSymbols];

  const updatedUser = await apollo.mutate({
    mutation: gql`
      mutation ($userId: ID!, $symbolIds: [SecurityWhereUniqueInput!]) {
        updateUser(
          where: { id: $userId }
          data: { watchlist: { connect: $symbolIds } }
        ) {
          ...userInfo
        }
      }
      ${userInfoFragment}
    `,
    variables: { symbolIds: symbolsInDatabase, userId },
  });

  return updatedUser.data;
}

export async function questradeRefresh({
  user: { id, qtCred },
}: {
  user: UserType;
}) {
  const refreshToken = qtCred.refreshToken;

  const newCreds = await refreshCredentials(refreshToken).then(
    (res) => res?.data
  );

  const transcribedCreds = transcribeRawCreds(newCreds);

  return questradeAuthAndUpdateUser({
    id,
    credentials: transcribedCreds,
  });
}

export async function refreshCredentials(refreshToken?: string) {
  if (!refreshToken) {
    return;
  }
  return await axios.post("/api/private/questrade/refresh", {
    refreshToken,
  });
}

export async function searchSymbol(
  keyword: string,
  credentials: QuestradeCredentials
): Promise<{ symbols: Array<QuestradeSymbol> }> {
  return await questradeBackendRequest("search", { keyword });
}

export async function deleteFromWatchlist({
  symbolIds,
  userId,
}: {
  symbolIds: Array<number>;
  userId: string;
}) {
  const symbols = symbolIds.map((symbolId) => ({ symbolId }));
  const updateUserRes = await apollo.mutate({
    mutation: gql`
      mutation ($symbols: [SecurityWhereUniqueInput!]!, $userId: ID!) {
        updateUser(
          where: { id: $userId }
          data: { watchlist: { disconnect: $symbols } }
        ) {
          ...userInfo
        }
      }
      ${userInfoFragment}
    `,
    variables: { symbols, userId },
  });
  return updateUserRes.data;
}

export async function getOrComputeMargins({
  symbolId,
  checkExisting = true,
}: {
  symbolId: number;
  checkExisting?: boolean;
}) {
  if (checkExisting) {
    const oneHourAgo = new Date(new Date().getTime() - ONE_HOUR).toISOString();
    // get margin created <= 1 hour ago with the symbolId
    const marginQuery = await apollo.query({
      query: gql`
        query ($symbolId: Int!, $oneHourAgo: DateTime!) {
          marginCalls(
            where: {
              symbol: { symbolId: { equals: $symbolId } }
              createdAt: { gte: $oneHourAgo }
            }
            orderBy: { createdAt: desc }
          ) {
            margins
          }
        }
      `,
      variables: { symbolId, oneHourAgo },
    });

    const existingMargins = marginQuery?.data?.marginCalls;
    if (existingMargins?.length) {
      return existingMargins?.[0].margins;
    }
  }

  const stockQuotes = getStockQuotes([symbolId]);
  const optionQuotes = getOptionIds(symbolId).then((optionIds) =>
    getOptionQuotes(optionIds)
  );

  let margins: MarginType[] = [];
  return await Promise.all([stockQuotes, optionQuotes])
    .then(([stockQuotes, optionQuotes]) => {
      const stockQuote = stockQuotes?.[0];
      const { bidPrice: stockBidPrice } = stockQuote;
      for (const optionQuote of optionQuotes) {
        const {
          symbol: optionSymbol,
          askPrice: optionAsk,
          askSize,
        } = optionQuote;
        if (!askSize) {
          continue;
        }
        const optionType = getOptionTypeFromSymbol(optionSymbol);
        const strikePrice = getOptionStrikeFromSymbol(optionSymbol);
        let intrinsicValue = stockBidPrice - strikePrice;
        if (optionType === "P") {
          intrinsicValue *= -1;
        }
        const delta = intrinsicValue - optionAsk;
        const margin: MarginType = {
          delta,
          symbolId,
          strikePrice,
          optionAsk,
          intrinsicValue,
          optionSymbol,
        };

        margins.push(margin);
      }

      return margins;
    })
    .then((margins) => {
      apollo.mutate({
        mutation: gql`
          mutation ($margins: JSON!, $symbolId: Int!) {
            createMarginCall(
              data: {
                symbol: { connect: { symbolId: $symbolId } }
                margins: $margins
              }
            ) {
              id
            }
          }
        `,
        variables: { margins, symbolId },
      });

      return margins;
    });
}

async function getOptionQuotes(optionIds: number[]): Promise<OptionQuotes[]> {
  let optionQuotes: OptionQuotes[] = [];

  if (!optionIds?.length) {
    return [];
  } else if (optionIds.length <= 100) {
    return (await questradeBackendRequest("option-quote", { optionIds }))
      ?.optionQuotes;
  }

  // otherwise recurse every 100
  let chunk: number[];
  const apiCalls = [];
  do {
    chunk = optionIds.splice(0, 100);
    apiCalls.push(
      getOptionQuotes(chunk).then((quotes) => {
        optionQuotes.push(...quotes);
      })
    );
  } while (chunk.length);

  await Promise.all(apiCalls);
  return optionQuotes;
}

async function getOptionIds(symbolId: number) {
  const optionChain = (
    await questradeBackendRequest("option-chain", { symbolId })
  )?.optionChain;

  const optionIds: number[] = [];

  for (const expiry of optionChain) {
    const chainPerStrikePrice = expiry?.chainPerRoot?.[0]?.chainPerStrikePrice;
    for (const strikePrice of chainPerStrikePrice) {
      optionIds.push(strikePrice.callSymbolId);
      optionIds.push(strikePrice.putSymbolId);
    }
  }

  return optionIds;
}

export async function getStockQuotes(symbolIds: number[]) {
  const res = await questradeBackendRequest("stock-quote", { symbolIds });
  return res?.quotes as StockQuote[];
}

async function questradeBackendRequest(
  suffix: string,
  payload: Record<string, any>,
  retryCount: number = 0
): Promise<any> {
  const user = store.getState().user.user;
  if (user?.id) {
    const expiresIn = new Date(user.qtCred.expiresAt).getTime() - Date.now();
    if (expiresIn < ONE_MINUTE) {
      await store.dispatch(userActions.refreshTokenThunk({ user }));
    }
  }
  return await axios
    .post(`/api/private/questrade/${suffix}`, payload)
    .then((res) => res?.data)
    .catch(async (err) => {
      if (
        err?.response?.status === 429 &&
        retryCount < MAX_BACKEND_API_CALL_COUNT
      ) {
        const resetTime = err.response?.headers?.["x-ratelimit-reset"];
        await sleepUntil(resetTime);
        return await questradeBackendRequest(suffix, payload, retryCount + 1);
      } else {
        console.error(err);
        return null;
      }
    });
}

async function getStreamSocketPort(symbolIds: number[]) {
  const res = await questradeBackendRequest("stream-port", { symbolIds });
  return res?.streamPort;
}

export async function streamQuotes(
  symbolIds: number[],
  onQuotes: (quotes: StockQuote[]) => void
) {
  const streamPort = await getStreamSocketPort(symbolIds);
  if (!streamPort) {
    return null;
  }
  const credentials = store.getState().user.user?.qtCred;
  if (!credentials) {
    return null;
  }

  const baseApiServer = credentials.apiServer.slice(7, -1);

  const websocketUrl = `wss://${baseApiServer}:${streamPort}/v1/markets/quotes?stream=true&mode=WebSocket&ids=[${symbolIds.join(
    ","
  )}]`;
  const ws = new WebSocket(websocketUrl);
  ws.addEventListener("open", () => {
    ws.send(credentials.accessToken);
  });

  ws.addEventListener("message", (e) => {
    const quotes = (JSON.parse(e?.data)?.quotes || []) as StockQuote[];
    onQuotes(quotes);
  });

  return ws;
}

export async function symbolInfo(symbolIds: number[]) {
  const res = await questradeBackendRequest("/symbol-info", { symbolIds });
  return res;
}
