import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Toolbar,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks/redux";
import { userActions, userSelectors } from "../reducers/userReducer";
import { MarginType } from "../types";

const marginGridColumns: GridColDef[] = [
  {
    field: "symbolId",
    headerName: "Symbol ID",
    editable: true,
    align: "left",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "optionSymbol",
    headerName: "Option",
    align: "left",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "strikePrice",
    headerName: "Strike Price",
    align: "left",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "optionAsk",
    headerName: "Option Ask",
    align: "left",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "intrinsicValue",
    headerName: "Intrinsic Value",
    align: "left",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "delta",
    headerName: "Delta",
    align: "right",
    headerAlign: "right",
    flex: 1,
  },
];

export default function Margins() {
  const loading = useSelector(userSelectors.isLoading);
  const [symbolId, setSymbolId] = useState<number>();
  const user = useSelector(userSelectors.user);
  const watchlist = user?.watchlist;
  const dispatch = useAppDispatch();
  const [checkExisting, setCheckExisting] = useState(true);
  const [margins, setMargins] = useState<MarginType[]>([]);
  const watchlistOptions = useMemo(() => {
    if (!watchlist) return [];
    return watchlist?.map((watchlistSymbol) => {
      return {
        label: watchlistSymbol.symbol,
        symbolId: watchlistSymbol.symbolId,
      };
    });
  }, [watchlist]);
  useEffect(() => {
    if (symbolId) {
      setMargins([]);
    }
  }, [symbolId]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (user?.id && symbolId) {
      dispatch(
        userActions.getOrComputeMarginsThunk({
          symbolId,
          checkExisting,
        })
      ).then((action) => {
        setMargins(action?.payload || []);
      });
    }
  };
  return (
    <>
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Box>
          <Autocomplete
            options={watchlistOptions}
            renderInput={(params) => {
              return <TextField {...params} />;
            }}
            onChange={(_e, newValue) => {
              setSymbolId(newValue?.symbolId);
            }}
            disabled={!watchlistOptions.length}
            isOptionEqualToValue={(
              { symbolId: optionSymbolId },
              { symbolId: valueSymbolId }
            ) => optionSymbolId === valueSymbolId}
          />
        </Box>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checkExisting}
                  onChange={() => {
                    setCheckExisting((checkExisting) => !checkExisting);
                  }}
                />
              }
              label="Check existing"
            />
          </FormGroup>
        </Box>
        <Box>
          <LoadingButton
            type="submit"
            disabled={!Boolean(symbolId)}
            loading={loading}
          >
            Search
          </LoadingButton>
        </Box>
      </Box>

      <Toolbar />
      <DataGrid
        autoHeight={true}
        rows={margins}
        columns={marginGridColumns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.optionSymbol}
        loading={loading}
        initialState={{
          sorting: { sortModel: [{ field: "delta", sort: "desc" }] },
        }}
      />
    </>
  );
}
