import { Link as MuiLink } from "@mui/material";
import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export const Link = forwardRef<any, RouterLinkProps>((props, ref) => (
  <MuiLink component={RouterLink} ref={ref} {...props} />
));
