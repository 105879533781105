import { forwardRef } from "react";
import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";
import styles from "../styles/Sidebar.module.css";

export const NavLink = forwardRef<any, NavLinkProps>((props, ref) => {
  const muiClasses = props.className as string;
  return (
    <RouterLink
      ref={ref}
      {...props}
      className={({ isActive }) =>
        isActive ? `${muiClasses} ${styles.activeClass}` : muiClasses
      }
    />
  );
});
