import { QuestradeCredentials, RawQuestradeCredentials } from "./types";

export async function sleep(ms: number) {
  return new Promise<void>((r) => setTimeout(r, ms));
}

export async function sleepUntil(timeInSeconds: number) {
  const delta = timeInSeconds * 1000 - Date.now();
  return sleep(Math.max(0, delta));
}

export function getOptionTypeFromSymbol(symbol: string) {
  const callRegex = /(?:[a-z][0-9]{2}C)/;
  const optionType = symbol.match(callRegex) ? "C" : "P";
  return optionType;
}

export function getOptionStrikeFromSymbol(symbol: string) {
  let strikePrice = "";
  for (let i = symbol.length - 1; i >= 0; i--) {
    const c = symbol[i];
    if (c === "C" || c === "P") {
      break;
    }
    strikePrice = c + strikePrice;
  }

  return parseFloat(strikePrice);
}
export function parsePageTitleFromPath(pathname: string) {
  const firstSegment = pathname.split("/")?.[1];
  if (!firstSegment) {
    return "Home";
  }
  const kebabs = firstSegment
    .split("-")
    .map((kebab) => capitalizeFirstLetter(kebab));
  return kebabs.join(" ");
}

export function capitalizeFirstLetter(s: string) {
  if (!s) return s;
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export function transcribeRawCreds(rawCreds: RawQuestradeCredentials) {
  const res: QuestradeCredentials = {
    accessToken: rawCreds["access_token"],
    apiServer: rawCreds["api_server"],
    expiresAt: new Date(
      new Date().getTime() + rawCreds["expires_in"] * 1000
    ).toISOString(),
    refreshToken: rawCreds["refresh_token"],
    tokenType: rawCreds["token_type"],
  };
  return res;
}
