import { GridColDef } from "@mui/x-data-grid";

const { REACT_APP_CLIENT_ID, REACT_APP_HOST } = process.env;

const REDIRECT_URL = `${REACT_APP_HOST}/questrade-auth`;
export const AUTH_URL = `https://login.questrade.com/oauth2/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=token&redirect_uri=${REDIRECT_URL}`;
export const REFRESH_TOKEN_URL = `https://login.questrade.com/oauth2/token`;
export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_MINUTE = 60 * 1000;
export const MAX_BACKEND_API_CALL_COUNT = 5;

export const defaultSymbolGridColumns: GridColDef[] = [
  {
    field: "symbolId",
    headerName: "Questrade ID",
    align: "left",
    headerAlign: "left",
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "symbol",
    headerName: "Ticker",
    align: "right",
    headerAlign: "right",
    flex: 1,
    maxWidth: 100,
  },
];
