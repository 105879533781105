import {
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  addSymbolsToWatchlist,
  deleteFromWatchlist,
  getCurrentUser,
  getOrComputeMargins,
  questradeAuthAndUpdateUser,
  questradeRefresh,
  updateUser,
  userSignIn,
  userSignOut,
} from "../api";
import { RootState } from "../store";
import { NotificationType, UserType } from "../types";

const getUserThunk = createAsyncThunk("user/void/getUser", getCurrentUser);
const signInThunk = createAsyncThunk("user/void/signIn", userSignIn);
const questradeAuthThunk = createAsyncThunk(
  "user/update/questradeAuth",
  questradeAuthAndUpdateUser
);
const addToWatchlistThunk = createAsyncThunk(
  "user/update/addSymbolsToWatchlist",
  addSymbolsToWatchlist
);
const updateUserThunk = createAsyncThunk("user/update/updateUser", updateUser);
const deleteFromWatchlistThunk = createAsyncThunk(
  "user/update/deleteFromWatchlist",
  deleteFromWatchlist
);
const refreshTokenThunk = createAsyncThunk(
  "user/update/refreshToken",
  questradeRefresh
);
const getOrComputeMarginsThunk = createAsyncThunk(
  "user/void/getOrComputeMargins",
  getOrComputeMargins
);

type UserState = {
  loading: boolean;
  user?: UserType | null;
  notification?: NotificationType;
};

const initialState: UserState = {
  loading: false,
  user: undefined,
  notification: undefined,
};
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNotification: (state, action: PayloadAction<NotificationType>) => {
      state.notification = action.payload;
    },
    clearNotification: (state) => {
      state.notification = undefined;
    },
    signOut: (state) => {
      state.user = null;
      userSignOut();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.fulfilled, (state, action) => {
        if (action.payload?.authenticatedItem) {
          state.user = action.payload.authenticatedItem;
        } else {
          state.user = null;
        }
        state.loading = false;
      })
      .addCase(getUserThunk.rejected, (state) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(signInThunk.fulfilled, (state, action) => {
        if (
          action?.payload?.authenticateUserWithPassword["__typename"] ===
          "UserAuthenticationWithPasswordSuccess"
        ) {
          state.user = action?.payload?.authenticateUserWithPassword?.item;
        } else {
          state.user = null;
        }
        state.loading = false;
      })
      .addCase(signInThunk.rejected, (state) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(updateUserThunk.fulfilled, (state, action) => {
        if (action?.payload?.updateUser) {
          state.user = action.payload.updateUser;
        }
      })
      .addCase(questradeAuthThunk.fulfilled, (state, action) => {
        if (action?.payload?.updateUser) {
          state.user = action.payload.updateUser;
        }
      })
      .addCase(addToWatchlistThunk.fulfilled, (state, action) => {
        if (action?.payload?.updateUser) {
          state.user = action.payload.updateUser;
        }
      })
      .addMatcher<FulfilledAction>(
        (action) => action.type.split("/")?.[1] === "update",
        (state, action: PayloadAction<any, string>) => {
          if (action?.payload?.updateUser) {
            state.user = action.payload.updateUser;
          }
        }
      )
      .addMatcher<FulfilledAction>(
        () => true,
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action): action is PendingAction => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
        }
      );
  },
});

export const userActions = {
  ...userSlice.actions,
  getUserThunk,
  signInThunk,
  questradeAuthThunk,
  updateUserThunk,
  addToWatchlistThunk,
  refreshTokenThunk,
  deleteFromWatchlistThunk,
  getOrComputeMarginsThunk,
};

export const userSelectors = {
  isLoading: (state: RootState) => state.user.loading,
  user: (state: RootState) => state.user.user,
  notification: (state: RootState) => state.user.notification,
};

export default userSlice.reducer;
