import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux";
import { userActions, userSelectors } from "../reducers/userReducer";
import { QuestradeCredentials } from "../types";
import { Link } from "./Link";

export default function AuthRedirect() {
  const location = useLocation();
  const user = useSelector(userSelectors.user);
  const params = useMemo(() => {
    return new URLSearchParams(location.hash.slice(1));
  }, [location]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const requiredParams = [
      "access_token",
      "refresh_token",
      "token_type",
      "expires_in",
      "api_server",
    ];
    for (const param of requiredParams) {
      if (!params.has(param)) {
        return;
      }
    }

    const expiresIn = parseInt(params.get("expires_in")!);
    const expiresAt = new Date(
      new Date().getTime() + expiresIn * 1000
    ).toISOString();

    const credentials: QuestradeCredentials = {
      accessToken: params.get("access_token")!,
      refreshToken: params.get("refresh_token")!,
      apiServer: params.get("api_server")!,
      tokenType: params.get("token_type")!,
      expiresAt,
    };

    dispatch(userActions.questradeAuthThunk({ id: user?.id!, credentials }));
  }, [params, dispatch, user?.id]);

  return (
    <>
      <Link to="/profile">Go back to profile</Link>
    </>
  );
}
