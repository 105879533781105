import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid";
import _ from "lodash";
import { useEffect, useMemo, useReducer, useState } from "react";
import { streamQuotes } from "../api";
import extractedPairs from "../data/extractedPairs.json";
import symbolInfos from "../data/extractedSymbolInfos.json";
import { PairType, StockQuote } from "../types";
import RateTable from "./RateTable";

const pairTableRows = extractedPairs.map(([cadSymbol, usdSymbol]) => ({
  description: cadSymbol.description,
  cadTicker: cadSymbol.symbol,
  usdTicker: usdSymbol.symbol,
  cadExchange: cadSymbol.listingExchange,
  usdExchange: usdSymbol.listingExchange,
  usdSymbolId: usdSymbol.symbolId,
  cadSymbolId: cadSymbol.symbolId,
  maxAverageVol: Math.max(
    (symbolInfos as any)?.[cadSymbol.symbolId].averageVol3Months,
    (symbolInfos as any)?.[usdSymbol.symbolId].averageVol3Months
  ),
}));
const pairTable = _.chain(pairTableRows).keyBy("usdSymbolId").value();
// const pairTableRowIds = Object.keys(pairTable);

const columns: GridColDef[] = [
  {
    field: "description",
    headerName: "Description",
    align: "left",
    headerAlign: "left",
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "cadTicker",
    headerName: "CAD Ticker",
    align: "right",
    headerAlign: "right",
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "usdTicker",
    headerName: "USD Ticker",
    align: "right",
    headerAlign: "right",
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "cadExchange",
    headerName: "CAD Exchange",
    align: "right",
    headerAlign: "right",
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "usdExchange",
    headerName: "USD Exchange",
    align: "right",
    headerAlign: "right",
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "maxAverageVol",
    maxWidth: 0,
  },
];

export default function Sandbox() {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
  const [isExpanded, setIsExpanded] = useState(true);
  const [streaming, setStreaming] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const selectedPairTable: Record<number, PairType> = useMemo(() => {
    const selectedPt: Record<number, PairType> = {};
    selectionModel?.forEach((usdSymbolId) => {
      selectedPt[usdSymbolId as number] = pairTable[usdSymbolId];
    });
    return selectedPt;
  }, [selectionModel]);

  const usdSymbolIds = useMemo(() => {
    return Object.keys(selectedPairTable).map((usdSymbolId) =>
      parseInt(usdSymbolId)
    );
  }, [selectedPairTable]);

  const initialSymbolQuotes: Record<number, StockQuote | null> = useMemo(() => {
    const initialSq: Record<number, StockQuote | null> = {};
    for (const usdSymbolId of usdSymbolIds) {
      const cadSymbolId = pairTable[usdSymbolId].cadSymbolId;
      initialSq[usdSymbolId] = null;
      initialSq[cadSymbolId] = null;
    }
    return initialSq;
  }, [usdSymbolIds]);
  const [quotes, dispatch] = useReducer(updateSymbolQuote, initialSymbolQuotes);

  const allSymbolIds = useMemo(
    () =>
      Object.keys(initialSymbolQuotes).map((symbolId) => parseInt(symbolId)),
    [initialSymbolQuotes]
  );

  function updateSymbolQuote(
    state: Record<number, StockQuote | null>,
    quotes: StockQuote[]
  ) {
    const newState = { ...state };
    for (const quote of quotes) {
      const { symbolId } = quote;
      newState[symbolId] = quote;
    }
    // setLastUpdated(new Date());
    return newState;
  }

  useEffect(() => {
    if (allSymbolIds?.length) {
      streamQuotes(allSymbolIds, (quotes) => {
        dispatch(quotes);
      });
    }
  }, [allSymbolIds]);

  const handleSymbolSelect = (selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  };
  return (
    <>
      <Box>
        Stream
        <Switch
          onChange={(e) => setStreaming(e.target.checked)}
          checked={streaming}
          disabled={!Boolean(selectedPairTable)}
        />
      </Box>
      {/* <Typography>{lastUpdated.getTime()}</Typography> */}

      <Grid
        container
        direction="row"
        justifyContent={"space-around"}
        spacing={1}
      >
        <Grid item xs={true}>
          <RateTable
            pairTable={selectedPairTable}
            disabled={!streaming}
            quotes={quotes}
          />
        </Grid>
        <Grid item xs={isExpanded ? "auto" : 1}>
          <IconButton
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              px: [1],
            }}
          >
            {isExpanded ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
          {isExpanded && (
            <DataGrid
              autoHeight={true}
              rows={pairTableRows}
              columns={columns}
              rowsPerPageOptions={[10, 50, 100]}
              checkboxSelection
              getRowId={(row) => row.usdSymbolId}
              onSelectionModelChange={handleSymbolSelect}
              selectionModel={selectionModel}
              initialState={{
                sorting: {
                  sortModel: [{ field: "maxAverageVol", sort: "desc" }],
                },
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
