import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField, Toolbar } from "@mui/material";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { defaultSymbolGridColumns } from "../constants";
import { searchSymbol } from "../api";
import { useAppDispatch } from "../hooks/redux";
import { userActions, userSelectors } from "../reducers/userReducer";
import { QuestradeSymbol } from "../types";

type ResultsType = Record<number, QuestradeSymbol>;

export default function SearchSymbols() {
  const user = useSelector(userSelectors.user);
  const dispatch = useAppDispatch();
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState<ResultsType>({});
  const [loading, setLoading] = useState(false);
  const [selectedSymbolIds, setSelectedSymbolIds] = useState<Array<number>>([]);
  const userIsLoading = useSelector(userSelectors.isLoading);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
  }, [dispatch, user?.id]);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (user?.qtCred) {
      const res = await searchSymbol(keyword, user?.qtCred);
      const searchResults: ResultsType = {};
      res?.symbols?.forEach((symbol) => {
        searchResults[symbol.symbolId] = symbol;
      });
      setResults(searchResults || {});
    }
    setLoading(false);
  };

  const handleSymbolSelect = (selectionModel: GridSelectionModel) => {
    setSelectedSymbolIds(selectionModel as Array<number>);
  };

  const handleAddToWatchlist = async (e: React.FormEvent) => {
    e.preventDefault();
    if (user?.id && selectedSymbolIds.length) {
      const selectedSymbols = selectedSymbolIds.map(
        (symbolId) => results[symbolId]
      );
      dispatch(
        userActions.addToWatchlistThunk({
          userId: user.id,
          symbols: selectedSymbols,
        })
      );
    }
  };

  return (
    <>
      <Box component="form" onSubmit={handleSearch} autoComplete="off">
        <Box>
          <TextField
            id="keyword"
            label="Keyword"
            type="search"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value.toUpperCase());
            }}
          />
        </Box>
        <Box>
          <LoadingButton
            type="submit"
            disabled={!Boolean(keyword)}
            loading={loading}
          >
            Search
          </LoadingButton>
        </Box>
      </Box>

      <Toolbar />
      <Grid container direction="column" textAlign="left" width={"auto"}>
        <Grid item>
          <DataGrid
            autoHeight={true}
            rows={Object.values(results)}
            columns={defaultSymbolGridColumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            getRowId={(row) => row.symbolId}
            onSelectionModelChange={handleSymbolSelect}
            loading={loading}
            sx={{ maxWidth: 300 }}
          />
        </Grid>
        <Grid item>
          <LoadingButton
            disabled={!Boolean(selectedSymbolIds.length)}
            loading={userIsLoading}
            onClick={handleAddToWatchlist}
            variant="contained"
          >
            Add to Watchlist
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
