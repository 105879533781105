import { Grid, Toolbar, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { userSelectors } from "../reducers/userReducer";
import { Watchlist } from "./Watchlist";

export default function Profile() {
  const user = useSelector(userSelectors.user);

  const expiryDate = DateTime.fromISO(user?.qtCred.expiresAt || "");

  return (
    <>
      <Typography variant="h4">Welcome {user?.name}</Typography>
      <Toolbar />
      <Grid container direction="row" justifyContent="space-between">
        <Grid item lg={8}>
          <Typography variant="h5">Questrade Info:</Typography>
          {user?.qtCred ? (
            <>
              <Typography variant="body1">
                Refresh Token: {user?.qtCred?.refreshToken}
              </Typography>
              <Typography variant="body1">
                Access Token: {user?.qtCred?.accessToken}
              </Typography>
              <Typography variant="body1">
                Expiry:{" "}
                {`${expiryDate.toLocaleString(
                  DateTime.DATETIME_SHORT
                )} (${expiryDate.toRelative()})`}
              </Typography>
            </>
          ) : (
            "Not authenticated"
          )}
        </Grid>
        <Grid item lg={4}>
          <Watchlist />
        </Grid>
      </Grid>
      <Toolbar />
    </>
  );
}
