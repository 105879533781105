import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { userSelectors } from "../reducers/userReducer";
import AuthRedirect from "./AuthRedirect";
import Home from "./Home";
import Margins from "./Margins";
import Profile from "./Profile";
import Sandbox from "./Sandbox";
import SearchSymbols from "./SearchSymbols";
import SignIn from "./SignIn";

export default function AppRouter() {
  const user = useSelector(userSelectors.user);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile" element={user ? <Profile /> : <SignIn />} />
      <Route path="/questrade-auth" element={<AuthRedirect />} />
      <Route path="/search-symbols" element={<SearchSymbols />} />
      <Route path="/margins" element={<Margins />} />
      <Route path="/sandbox" element={<Sandbox />} />
    </Routes>
  );
}
