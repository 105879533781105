import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    drawerWidth: number;
  }
  interface ThemeOptions {
    drawerWidth?: number;
  }
}

const theme = createTheme({
  drawerWidth: 240,
  palette: {},
});

export default theme;
