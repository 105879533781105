import MenuIcon from "@mui/icons-material/Menu";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AUTH_URL } from "../constants";
import { parsePageTitleFromPath } from "../helpers";
import { useAppDispatch } from "../hooks/redux";
import { userActions, userSelectors } from "../reducers/userReducer";
import { HeaderBar } from "./HeaderBar";

interface HeaderProps {
  open: boolean;
  toggleDrawer: () => void;
}
export default function Header({ open, toggleDrawer }: HeaderProps) {
  const user = useSelector(userSelectors.user);
  const isLoading = useSelector(userSelectors.isLoading);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const locationName = useMemo(
    () => parsePageTitleFromPath(location.pathname),
    [location]
  );
  const handleSignOut = () => {
    dispatch(userActions.signOut());
  };
  const handleRefreshCreds = async () => {
    if (user?.id) {
      dispatch(userActions.refreshTokenThunk({ user }));
    }
  };

  return (
    <HeaderBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {locationName}
        </Typography>
        {user?.qtCred && (
          <LoadingButton
            color="inherit"
            loading={isLoading}
            onClick={handleRefreshCreds}
          >
            Refresh Credentials
          </LoadingButton>
        )}
        {user?.id && (
          <>
            <Button href={AUTH_URL} color="inherit">
              Sign In to Questrade
            </Button>
            <Button onClick={handleSignOut} color="inherit">
              Sign Out
            </Button>
          </>
        )}
      </Toolbar>
    </HeaderBar>
  );
}
