import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import { useState } from "react";
import { useSelector } from "react-redux";
import { defaultSymbolGridColumns } from "../constants";
import { useAppDispatch } from "../hooks/redux";
import { userActions, userSelectors } from "../reducers/userReducer";

export function Watchlist() {
  const user = useSelector(userSelectors.user);
  const [selectedSymbolIds, setSelectedSymbolIds] = useState<Array<number>>([]);
  const watchlist = user?.watchlist || [];
  const isLoading = useSelector(userSelectors.isLoading);
  const dispatch = useAppDispatch();

  const handleSymbolSelect = (selectionModel: GridSelectionModel) => {
    setSelectedSymbolIds(selectionModel as Array<number>);
  };

  const handleDeleteFromWatchlist = () => {
    if (selectedSymbolIds && user?.id) {
      dispatch(
        userActions.deleteFromWatchlistThunk({
          symbolIds: selectedSymbolIds,
          userId: user.id,
        })
      );
    }
  };

  return (
    <Grid container direction="column" textAlign="right">
      <Grid item>
        <DataGrid
          autoHeight={true}
          rows={watchlist}
          columns={defaultSymbolGridColumns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          getRowId={(row) => row.symbolId}
          onSelectionModelChange={handleSymbolSelect}
          loading={isLoading}
        />
      </Grid>
      <Grid item>
        <LoadingButton
          loading={isLoading}
          disabled={!Boolean(selectedSymbolIds.length)}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={handleDeleteFromWatchlist}
        >
          Delete
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
