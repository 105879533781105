import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";
import { createApolloClient } from "../api";
import { useAppDispatch } from "../hooks/redux";
import { userActions } from "../reducers/userReducer";
import AppRouter from "./AppRouter";
import PageWrapper from "./PageWrapper";

function App() {
  const apollo = createApolloClient();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(userActions.getUserThunk());
  }, [dispatch]);
  return (
    <ApolloProvider client={apollo}>
      <PageWrapper>
        <AppRouter />
      </PageWrapper>
    </ApolloProvider>
  );
}

export default App;
